/**
 * This is an example of how you can customize the theme of this web app.  To use:
 * 1) Copy this file into a `custom.scss` file.
 * 2) Uncomment the import statement in app.scss.
 * 3) Run gulp to compile it.
 */
$main-color:                #f6f6f6;
$secondary-color:           #b00e00;
$accent-color:              #019245;
$brand-primary:             $main-color !default;
$btn-primary-color:         $main-color;
$btn-primary-bg:            $secondary-color;
$btn-success-color:         $main-color !default;
$btn-success-bg:            $secondary-color;
$btn-info-color:            $main-color !default;
$btn-info-bg:               $accent-color;
$btn-default-color:         $secondary-color !default;
$btn-default-bg:            $main-color;
$brand-success:             $accent-color !default;
$brand-danger:              $secondary-color !default;
$font-size-base:            18px !default;
$panel-default-heading-bg:  $secondary-color !default;
$blockquote-font-size:      14px;
$blockquote-border-color:   $secondary-color;
$blockquote-small-color:    $accent-color;

.panel-default .panel-heading a {
    color: $main-color;
}
.panel-default .panel-body a.btn {
    color: $btn-info-color;
}
.panel-default .panel-body a {
  color: $secondary-color;
}
#prayer-details {
    margin-top: 20px;
    margin-bottom: 20px;
}
.picker__year, .picker__weekday {
    color: $main-color;
}
.picker__day--today:before {
    border-top: 0.5em solid $secondary-color;
}
.picker__month,
.picker__day--outfocus {
    color: $secondary-color;
}
.picker__day--selected:hover,
.picker__day--highlighted:hover,
.picker__day--infocus:hover,
.picker__day--outfocus:hover,
.picker__button--today:hover,
.picker__button--clear:hover,
.picker__button--close:hover,
.picker__nav--prev:hover,
.picker__nav--next:hover {
    color: #ffffff;
    background: $secondary-color;
}
.picker__day--highlighted {
    border: none;
    border-color: none;
}
.picker--focused .picker__day--highlighted,
.picker__day--selected,
.picker--focused .picker__day--selected {
    color: #ffffff;
    background: $secondary-color;
}
.picker__button--today:before {
    border-top: 0.66em solid $secondary-color;
}
.picker__nav--prev:before,
.picker__nav--next:before {
    border-top: .5em solid transparent;
    border-bottom: .5em solid transparent;
    border-right: 0.75em solid $main-color;
}
.picker__nav--next:before {
    border-right: 0;
    border-left: 0.75em solid $main-color;
}
.picker__nav--prev:hover:before {
    border-top: .5em solid transparent;
    border-bottom: .5em solid transparent;
    border-right: 0.75em solid #ffffff;
}
.picker__nav--next:hover:before {
    border-top: .5em solid transparent;
    border-bottom: .5em solid transparent;
    border-right: 0;
    border-left: 0.75em solid #ffffff;
}
// Nav bar
.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
  color: $main-color !important;
  background-color: $secondary-color !important;
}
.navbar-inverse .navbar-nav > .active > a.open-jordan-panel, .navbar-inverse .navbar-nav > .active > a.open-jordan-panel:focus,
.navbar-inverse .navbar-nav > li > a.open-jordan-panel:focus {
  color: $secondary-color !important;
  background-color: $main-color !important;
}
.navbar-inverse .navbar-nav > li > a,
.navbar-inverse .dropdown-menu > li > a, .navbar-inverse .dropdown-menu > li > a:focus {
  color: $secondary-color !important;
  font-weight: bold !important;
}
.navbar-inverse .navbar-toggle .icon-bar {
  background-color: $secondary-color !important;
}
.logo-wrap {
  margin-right: 15px;
  .logo {
    max-width: 150px;
  }
}
.wrapper-inverse, .highlight {
  color: $secondary-color !important;
}
h2#daily-prayer, h2#learn-more, .prayer-count, .mdi-account-multiple {
  color: $accent-color;
}
blockquote {
  color: $accent-color !important;
}
.blockquote-reverse, blockquote.pull-right {
  border-width: 1px !important;
}
.owl-carousel {
  max-height: 650px;
  .item > img {
    width: auto;
    max-width: 100%;
    max-height: 650px;
    margin: 0px auto;
  }
}
